<template>
<v-container class="pl-6">
  <v-row class="d-flex align-center ma-0 pa-2">
    <div style="text-align: center; background-color: #f28d49; width: 28px; height: 28px;border-radius: 4px;">
      <v-icon color="#fff"> mdi-clipboard-text </v-icon>
    </div>
    <span class="ml-3 text-h6">推荐开启功能</span>
  </v-row>

  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1">提醒他人查看总结（@他人）</span>
    <v-switch v-model="settings.reportAlarmTa" @change="onChange"></v-switch>
  </v-row>
  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>

  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1">我的总结共享</span>
    <v-switch v-model="settings.reportMineShare" @change="onChange"></v-switch>
  </v-row>

</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";

export default {
  name: 'ReportSettingTab',
  data() {
    return {
      gettedSetting: {},
      initSetting: {
        reportAlarmTa: true, //提醒他人查看总结（@他人）
        reportMineShare: true, //我的总结共享
      },
    };
  },
  computed: {
    settings: {
      get() {
        let val = {
          ...this.initSetting,
          ...this.gettedSetting,
        };
        return val;
      },
      set(newValue) {
        
      }
    }
  },
  mounted() {
    this.getSettingCall({
      "st_category_id": 4, //tb_report
      "st_category_type": 101 //for this
    }).then(res => {
      if (res) {
        this.gettedSetting = res;
      }
    });
  },
  methods: {
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
    onChange() {
      this.setSettingCall({
        st_category_id: 4, //tb_report
        st_category_type: 101, //for this
        st_value: this.settings
      }, ).then(res => {
      });
    },
  }
}
</script>

<style scoped>
</style>
